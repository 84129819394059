<template>
    <div class="content-block card-slider-block" :class="wrapperClass">
        <div class="container">
            <div class="slick-custom-slider" v-if="data.list" data-scroll data-scroll-opacity data-scroll-in-bottom>
                <VueSlickCarousel v-bind="slickSettings" class="dots-carousel">
                    <template #prevArrow v-if="this.data.showArrows && this.data.showArrows === true">
                        <div class="slider-custom-arrow">
                            <span class="slider-arrow arrow-prev"><font-awesome-icon icon="chevron-left"/></span>
                        </div>
                    </template>
                    <template #nextArrow v-if="this.data.showArrows && this.data.showArrows === true">
                        <div class="slider-custom-arrow">
                            <span class="slider-arrow arrow-next"><font-awesome-icon icon="chevron-right"/></span>
                        </div>
                    </template>
                    <div v-for="sliderItem in data.list" :key="sliderItem.id" class="card-slide">
                        <img loading="lazy" :src="sliderItem.image.value" alt="slide" :style="imageStyle"/>
                    </div>
                </VueSlickCarousel>
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

library.add( faChevronLeft, faChevronRight );

export default {
    name: "CardSlider",
    props: {
        data: {
          type: Object,
          default: () => {},
        },
    },
    data(){
        return {
            slickSettings: {
                arrows: this.data.showArrows && this.data.showArrows === true,
                dots: this.data.showDots,
                slidesToShow: this.data.slidesAmount,
                slidesToScroll:1,
                swipe:false,
                autoplay:true,
                autoplaySpeed: 4000,
                responsive: [
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            swipe:true,
                            arrows: true,
                            dots: this.data.showDots
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            swipe:true,
                            arrows: true,
                            dots: this.data.showDots
                        },
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            swipe:true,
                            arrows: true,
                            dots: this.data.showDots
                        },
                    },
                ],
            }
        }
    },
    computed:{
        wrapperClass(){
            let base = {};
            if( this.data.shadowEnabled === true ){
                base[ 'shadow-container' ] = true;
            }

            if( this.data.arrowDesignPreset ) {
                base[ this.data.arrowDesignPreset ] = true;
            } else {
                base[ 'light-arrow' ] = true;
            }

            if (this.data.background != 'white') base[`background-${this.data.background}`] = true

            if( this.data.additionalClass ){
                let temp = this.data.additionalClass.split( ' ' );
                if( Array.isArray( temp ) && temp.length > 0 ){
                    temp.forEach( className => {
                        base[className] = true;
                    } )
                }
            }

            if (this.data.showArrows && this.data.showArrows === true)
            {
                base[ 'with-arrows' ] = true;
            }
            return base;
        },
        imageStyle(){
            let base = {};
            if( !this.data ){
                return base;
            }

            if( this.data.slidesContain ){
                base[ 'object-fit' ] = 'contain';
            }

            return base;
        }
    },
    components:{
        VueSlickCarousel
    },
};
</script>

